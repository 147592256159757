<template>
  <NuxtLink
    :to="setArticleRouteObject(props.article.UrlKey)"
    class="group block border-gray-300 rounded-xl border bg-[#2C2CA40D]"
  >
    <B2CTeaserImage
      :imageUrl="props.article.ImageUrl"
      :alt="props.article.Headline"
      :width="1050"
      :height="575"
      loading-mode="eager"
      class="w-full h-full rounded-t-xl"
      preload
    />

    <div class="w-full md:w-9/10 p-4 md:p-8">
      <B2CArticleTeaserLabel
        type="podcast"
        :is-first-article="true"
        :article="props.article"
        class="mb-2"
      />
      <div class="flex overflow-hidden justify-between w-full">
        <h2 class="headline-m" v-if="props.article.Headline">
          {{ props.article.Headline }}
        </h2>
        <div class="pl-4 flex justify-center items-center">
          <B2CPodcastPlayIcon :article="props.article" />
        </div>
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'

const props = defineProps<{
  article: ContentArticle
}>()
</script>
